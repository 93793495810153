import { state, style, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment-timezone';
import { NgxIntervalDataGridRowModel, WeekDays } from 'ngx-interval-data-grid';
import { Subscription } from 'rxjs';
import { GroupedData } from '../../../shared/models';
import { UIState } from '../../../shared/models/utility';
import { MixPanelService } from '../../../shared/services/mixpanel.service';

@Component({
  selector: 'dr-customer-offers-ui-cleared-table',
  templateUrl: './cleared-tab-table.component.html',
  styleUrls: ['./cleared-tab-table.component.scss'],
  animations: [trigger('detailExpand', [state('collapsed', style({ height: '0px', minHeight: '0' })), state('expanded', style({ height: '*' }))])],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClearedTabTableComponent implements OnInit, OnDestroy {
  @Input()
  set groupedData(g: GroupedData | null) {
    this._groupedData = g;
    if (!g) return;
    const timeZone = g.regConfig ? g.regConfig.timeZone : 'UTC';
    this.timezoneAbbr = moment.tz(g.regConfig ? g.regConfig.timeZone : 'UTC').zoneName();
    this.dataSource.data = g.values ?? [];
    const startDate = g.selectedDateRange?.start
      ? moment.tz(g.selectedDateRange?.start?.format('YYYY-MM-DD HH:mm:ss'), timeZone)
      : moment.tz(new Date(), timeZone).startOf('day');

    this.dates = [];
    for (let i = 0; i < 7; i++) {
      const newDate = startDate.clone().add(i, 'days');
      this.dates.push(newDate.date());
    }
  }

  get groupedData(): GroupedData | null {
    return this._groupedData;
  }

  private _groupedData: GroupedData | null = null;
  subs = new Subscription();
  UIState = UIState;
  public dates: number[] = [];
  public timezoneAbbr!: string;

  // Define column headers and fields
  columns: string[] = WeekDays;
  displayedColumns: string[] = ['offer', 'time_period', ...this.columns];
  serverData: NgxIntervalDataGridRowModel[] = [];
  public dataSource = new MatTableDataSource<NgxIntervalDataGridRowModel>(this.serverData);

  protected expandedRow!: NgxIntervalDataGridRowModel | null;

  constructor(private mixPanelService: MixPanelService) {}

  ngOnInit(): void {
    this.mixPanelService.viewTab('Cleared');
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
